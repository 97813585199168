import request from '@/router/axios';
import {businessUrl} from "../config/env";

//新增
export const add = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/insertCanonicalParameter",
        method: "post",
        data: {
            ...row
        }
    })
}

export const  list = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectPageCanonicalParameter",
        method: "post",
        data: {
            ...row
        }
    })
}
//下拉
export const  standardCodelist = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectPageStandard",
        method: "post",
        data: {
            ...row
        }
    })
}

export const  feedbacklist = (row) => {
    return request({
        url: businessUrl + "qualityFeedback/selectPage",
        method: "post",
        data: {
            ...row
        }
    })
}